<template>
  <div>
    <input
      type="checkbox"
      id="confirm-checkout-modal"
      class="hidden modal-toggle"
      :checked="open"
    >
    <div

      class="modal"
    >
      <div
        class="modal-box"
        style="min-width: 500px;"
      >
        <h3 class="text-lg font-semibold mb-4">
          Checkout plan
        </h3>
        <p class="py-4">
          Selected plan: {{ selectedPlan.name }}
          <br>
          Currency: {{ selectedPlan.currency }}
          <br>
          Commitment: {{ selectedPlan.commitment }}
        </p>
        <p
          v-if="currencyMismatch"
          class="text-red-600 text-lg"
        >
          Contact support to change billing currency.
        </p>
        <div class="modal-action">
          <div class="flex justify-between w-full">
            <button
              @click="$emit('close')"
              class="btn-secondary text-base"
            >
              Cancel
            </button>
            <button
              v-if="!currencyMismatch"
              @click="$emit('confirm')"
              class="btn-primary text-base"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: { type: Boolean, default: false },
    selectedPlan: { type: Object, required: true },
    currencyMismatch: Boolean
  }
}
</script>
