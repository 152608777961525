<template>
  <div>
    <div class="wp-block-columns is-layout-flex wp-container-31">
      <div
        class="wp-block-column is-layout-flow"
        style="flex-basis:100%"
      >
        <div
          class="wp-block-ub-tabbed-content wp-block-ub-tabbed-content-holder wp-block-ub-tabbed-content-horizontal-holder-mobile wp-block-ub-tabbed-content-horizontal-holder-tablet"
          id="ub-tabbed-content-6a576b44-183c-44f6-a552-d3b2fa437580"
        >
          <div
            class="wp-block-ub-tabbed-content-tab-holder horizontal-tab-width-mobile horizontal-tab-width-tablet"
          >
            <div
              role="tablist"
              class="wp-block-ub-tabbed-content-tabs-title wp-block-ub-tabbed-content-tabs-title-mobile-horizontal-tab wp-block-ub-tabbed-content-tabs-title-tablet-horizontal-tab"
            >
              <div
                @click="showPlanType = 'individual'"
                class="wp-block-ub-tabbed-content-tab-title-wrap "
                :class="{ 'active': showPlanType === 'individual' }"
              >
                <div class="text-base wp-block-ub-tabbed-content-tab-title">
                  <strong>Individual Brain</strong>
                </div>
              </div>
              <div
                class="wp-block-ub-tabbed-content-tab-title-wrap"
                :class="{ 'active': false }"
              >
                <div
                  class="text-base wp-block-ub-tabbed-content-tab-title cursor-default opacity-75"
                >
                  <strong>Team Brain (Coming soon...)</strong>
                </div>
              </div>
              <!-- || Team plan is not available while under development ||-->

              <!-- <div
                @click="showPlanType = 'team'"
                class="wp-block-ub-tabbed-content-tab-title-wrap"
                :class="{ 'active': showPlanType === 'team' }"
              >
                <div class="text-base wp-block-ub-tabbed-content-tab-title">
                  <strong>Team Brain</strong>
                </div>
              </div> -->
              <div class="ml-auto p-1">
                <select
                  class="form-select border px-8 py-4 select-campaign-dropdown text-base"

                  v-model="currency"
                >
                  <option value="SEK">
                    SEK
                  </option>
                  <option value="USD">
                    USD
                  </option>
                  <option value="EUR">
                    EUR
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="wp-block-ub-tabbed-content-tabs-content">
            <div
              class="wp-block-ub-tabbed-content-tab-content-wrap active"
              tabindex="0"
            >
              <div class="wp-block-toggle-content-toggle-content">
                <div class="eb-parent-wrapper eb-parent-eb-toggle-gng6ud3">
                  <div
                    class="eb-toggle-gng6ud3 eb-toggle-wrapper"
                  >
                    <div
                      class="eb-toggle-heading"
                      style="display:block"
                    >
                      <input
                        type="checkbox"
                        class="eb-text-switch-checkbox"
                        id="eb-fda14610-1cbc-4ada-b8e6-b16b690dff08"
                        style="display:none"
                      >
                      <div class="eb-text-switch-wrapper">
                        <div
                          class="eb-text-switch-content"
                          style="margin-left:auto;margin-right:auto"
                        >
                          <label
                            class="eb-text-switch-label"
                            for="eb-fda14610-1cbc-4ada-b8e6-b16b690dff08"
                          >
                            <div class="eb-text-switch-toggle" />
                            <div
                              @click="commitment = commitment === 'monthly' ? 'yearly' : 'monthly'"
                              class="eb-switch-names"
                            >
                              <span
                                class="eb-toggle-primary-label-text"
                                :class="{'text-white': commitment === 'monthly'}"
                              >
                                <strong>Pay Monthly</strong><br>Commit monthly
                              </span>
                              <span
                                class="eb-toggle-secondary-label-text"
                                :class="{'text-white': commitment === 'yearly'}"
                              >
                                <strong>Pay Monthly</strong><br>Commit annually
                              </span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="eb-toggle-content">
                      <div
                        class="wp-block-columns is-layout-flex wp-container-14"
                        v-if="showPlanType === 'individual'"
                      >
                        <plan-box
                          v-for="plan in $options.plansConfig.indivudal"
                          :key="plan.id[commitment][currency]"
                          :plan="plan"
                          :commitment="commitment"
                          :currency="currency"
                          :current="currentPlan === plan.id[commitment][currency]"
                          @select="$emit('select', {planId: $event.id[commitment][currency], name: $event.name, currency, commitment})"
                        />
                      </div>
                      <div
                        class="wp-block-columns is-layout-flex wp-container-14"
                        v-else-if="showPlanType === 'team'"
                      >
                        <plan-box
                          v-for="plan in $options.plansConfig.team"
                          :key="plan.id[commitment][currency]"
                          :plan="plan"
                          :commitment="commitment"
                          :currency="currency"
                          :current="currentPlan === plan.id[commitment][currency]"
                          @select="$emit('select', {planId: $event.id[commitment][currency], name: $event.name, currency, commitment})"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="has-text-align-center">
                <strong>Included in all plans</strong>
              </p>
              <p
                class="has-text-align-center"
                style="font-size:15px"
                v-if="showPlanType === 'individual'"
              >
                ✓ Unlimited reports/candidate<br>
                ✓ Requirement specification<br>
                ✓ Dashboard - unlimited sorting, filtering and matching<br>
                ✓ Personalized video tutorial for both user and candidate
              </p>
              <p
                class="has-text-align-center"
                style="font-size:15px"
                v-else-if="showPlanType === 'team'"
              >
                ✓ Team Dashboard - Aggregated view of all tested individuals<br>
                ✓ Team Cognitive Spider<br>
                ✓ Individual Profile Reports for team members<br>
                ✓ Individual Brain according to package (“starter” in “starter” etc)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PlanBox from './PlanBox.vue'
import { plansConfig } from '@/utils/constants'

export default {
  components: {
    PlanBox
  },
  props: {
    currentPlan: {
      type: String,
      required: true
    },
    initCurrency: {
      type: String,
      required: true
    },
    firstCheckout: Boolean
  },
  data () {
    return {
      showPlanType: 'individual',
      showMonthly: true,
      currency: 'USD',
      commitment: 'monthly'
    }
  },
  watch: {
    initCurrency () {
      this.currency = this.initCurrency
    }
  },
  mounted () {
    if (!this.firstCheckout) {
      if (this.currentPlan.includes('SEK')) {
        this.currency = 'SEK'
      } else if (this.currentPlan.includes('USD')) {
        this.currency = 'USD'
      } else {
        this.currency = 'EUR'
      }
    }
  },
  plansConfig
}
</script>

<style scoped src="./css2.css"></style>
