export const plansConfig = {
  indivudal: [
    {
      name: 'Starter',
      id: {
        monthly: {
          USD: 'starter-USD-Monthly',
          EUR: 'starter-EUR-Monthly',
          SEK: 'starter-SEK-Monthly'
        },
        yearly: {
          USD: 'starter-USD-Yearly',
          EUR: 'starter-EUR-Yearly',
          SEK: 'starter-SEK-Yearly'
        }
      },
      price: {
        monthly: {
          SEK: 2499,
          EUR: 249,
          USD: 249
        },
        yearly: {
          SEK: 1999,
          EUR: 199,
          USD: 199
        }
      },
      entitlements: {
        monthly: ['3 Full Profile Reports / month', '€199 / additional reports', 'Unlimited testing ', '1 user', '2 job positions / month'],
        yearly: ['36 Full Profile Reports / year', '€199 / additional reports', 'Unlimited testing ', '1 user', '2 job positions / month']
      }
    },
    {
      name: 'Plus',
      id: {
        monthly: {
          USD: 'plus-USD-Monthly',
          EUR: 'plus-EUR-Monthly',
          SEK: 'plus-SEK-Monthly'
        },
        yearly: {
          USD: 'plus-USD-Yearly',
          EUR: 'plus-EUR-Yearly',
          SEK: 'plus-SEK-Yearly'
        }
      },
      price: {
        monthly: {
          SEK: 6299,
          EUR: 629,
          USD: 629
        },
        yearly: {
          SEK: 4999,
          EUR: 499,
          USD: 499
        }
      },
      entitlements: {
        monthly: ['10 Full Profile Reports / month', '€99 / additional reports', 'Unlimited testing ', '3 users', 'Unlimited job positions'],
        yearly: ['120 Full Profile Reports / year', '€99 / additional reports', 'Unlimited testing ', '3 users', 'Unlimited job positions']
      }
    },
    {
      name: 'Enterprise',
      id: {
        monthly: {
          USD: 'enterprise-USD-Monthly',
          EUR: 'enterprise-EUR-Monthly',
          SEK: 'enterprise-SEK-Monthly'
        },
        yearly: {
          USD: 'enterprise-USD-Yearly',
          EUR: 'enterprise-EUR-Yearly',
          SEK: 'enterprise-SEK-Yearly'
        }
      },
      price: {
        monthly: {
          SEK: 12499,
          EUR: 1249,
          USD: 1249
        },
        yearly: {
          SEK: 9999,
          EUR: 999,
          USD: 999
        }
      },
      entitlements: {
        monthly: ['80 Full Profile Reports / month', '€39 / additional reports', 'Unlimited testing ', 'Unlimited users', 'Unlimited job positions'],
        yearly: ['960 Full Profile Reports / year', '€39 / additional reports', 'Unlimited testing ', 'Unlimited users', 'Unlimited job positions']
      }
    }
  ],
  team: [
    {
      name: 'Starter',
      id: {
        monthly: {
          SEK: 'team_brain_starter-SEK-Monthly',
          EUR: 'team_brain_starter-EUR-Monthly',
          USD: 'team_brain_starter-USD-Monthly'
        },
        yearly: {
          SEK: 'team_brain_starter-SEK-Yearly',
          EUR: 'team_brain_starter-EUR-Yearly',
          USD: 'team_brain_starter-USD-Yearly'
        }
      },
      price: {
        monthly: {
          SEK: 3099,
          EUR: 309,
          USD: 309
        },
        yearly: {
          SEK: 2499,
          EUR: 249,
          USD: 249
        }
      },
      entitlements: {
        monthly: ['1 Full Team Reports / month (10 ind/team)', '€1999 / additional Team Report'],
        yearly: ['12 Full Team Reports / year (10 ind/team)', '€1999 / additional Team Report']
      }
    },
    {
      name: 'Plus',
      id: {
        monthly: {
          SEK: 'team_brain_plus-SEK-Monthly',
          EUR: 'team_brain_plus-EUR-Monthly',
          USD: 'team_brain_plus-USD-Monthly'
        },
        yearly: {
          SEK: 'team_brain_plus-SEK-Yearly',
          EUR: 'team_brain_plus-EUR-Yearly',
          USD: 'team_brain_plus-USD-Yearly'
        }
      },
      price: {
        monthly: {
          SEK: 7499,
          EUR: 749,
          USD: 749
        },
        yearly: {
          SEK: 5999,
          EUR: 599,
          USD: 599
        }
      },
      entitlements: {
        monthly: ['3 Full Team Reports / month (10 ind/team)', '€1499 / additional Team Report'],
        yearly: ['36 Full Team Reports / year (10 ind/team)', '€1499 / additional Team Report']
      }
    },
    {
      name: 'Enterprise',
      id: {
        monthly: {
          SEK: 'team_brain_enterprise-SEK-Monthly',
          EUR: 'team_brain_enterprise-EUR-Monthly',
          USD: 'team_brain_enterprise-USD-Monthly'
        },
        yearly: {
          SEK: 'team_brain_enterprise-SEK-Yearly',
          EUR: 'team_brain_enterprise-EUR-Yearly',
          USD: 'team_brain_enterprise-USD-Yearly'
        }
      },
      price: {
        monthly: {
          SEK: 18799,
          EUR: 1879,
          USD: 1879
        },
        yearly: {
          SEK: 14999,
          EUR: 1499,
          USD: 1499
        }
      },
      entitlements: {
        monthly: ['7 Full Team Reports / month (10 ind/team)', '€999 / additional Team Report'],
        yearly: ['84 Full Team Reports / year (10 ind/team)', '€999 / additional Team Report']
      }
    }
  ]
}
