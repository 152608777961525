<template>
  <div class="wrapper my-profile">
    <div class="relative title-wrap">
      <h3
        v-if="inactiveSubscription"
        class="page-titles"
      >
        Free trial has expired.
      </h3>
      <h3
        v-else
        class="page-titles"
      >
        Change subscription
      </h3>
      <div class="profile">
        <the-profile class="print:hidden" />
      </div>
    </div>
    <pricing-table
      @select="selectedPlan = $event; openConfirmModal= true"
      :current-plan="$store.state.USER.user.plan"
      :init-currency="currency"
    />

    <confirm-checkout-modal
      :open="openConfirmModal"
      :selected-plan="selectedPlan"
      :currency-mismatch="!currentPlan.includes(selectedPlan.currency) && !firstCheckout"
      @close="openConfirmModal = false"
      @confirm="checkout"
    />
    <div>
      <input
        type="checkbox"
        id="plan-change-done-modal"
        class="hidden modal-toggle"
        :checked="showDoneModal"
      >
      <div
        class="modal"
      >
        <div
          class="modal-box"
          style="min-width: 500px;"
        >
          <h3 class="text-lg font-semibold mb-4">
            Plan changed! <span v-if="firstCheckout">(will take effect after free trial end)</span>
          </h3>
          <p class="py-4">
            You are now on: {{ selectedPlan }}
          </p>
          <div class="modal-action">
            <div class="flex justify-end w-full">
              <button
                @click="showDoneModal = false; $router.push('/profile')"
                class="btn-primary text-base"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <input
        type="checkbox"
        id="select-currency-modal"
        class="hidden modal-toggle"
        :checked="showCurrencyModal"
      >
      <div
        class="modal"
      >
        <div
          class="modal-box"
          style="min-width: 500px;"
        >
          <template
            v-if="inactiveSubscription"
          >
            <h3
              class="text-lg font-semibold mb-4"
            >
              Free trial has expired.
            </h3>
            <p>
              Before selecting a plan, please ensure that you choose your desired currency for payment.
            </p>
          </template>
          <template v-else>
            <h3 class="text-lg font-semibold mb-4">
              Select currency
            </h3>
            <p>
              Before selecting a plan, please ensure that you choose your desired currency for payment.
            </p>
          </template>
          <div
            class="mt-6"
          >
            <select
              class="form-select border px-8 py-4 select-campaign-dropdown text-base"
              v-model="currency"
            >
              <option value="SEK">
                SEK
              </option>
              <option value="USD">
                USD
              </option>
              <option value="EUR">
                EUR
              </option>
            </select>
          </div>
          <div class="modal-action">
            <div class="flex justify-end w-full">
              <button
                @click="showCurrencyModal = false"
                class="btn-primary text-base"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricingTable from '../components/PricingTable.vue'
import TheProfile from '@/components/TheProfile.vue'
import ConfirmCheckoutModal from '../components/ConfirmCheckoutModal.vue'

export default {
  components: { PricingTable, ConfirmCheckoutModal, TheProfile },
  props: {
    firstCheckout: {
      type: Boolean,
      defualt: false
    },
    inactiveSubscription: {
      type: Boolean,
      defualt: false
    }
  },
  data () {
    return {
      openConfirmModal: false,
      selectedPlan: {},
      showDoneModal: false,
      showCurrencyModal: this.firstCheckout,
      currency: 'USD'
    }
  },
  computed: {
    currentPlan () {
      return this.$store.state.USER.user.plan
    }
  },

  methods: {
    async checkout () {
      this.openConfirmModal = false

      if (this.firstCheckout) {
        const { data: hostedPage } = await this.$http.post('/subscriptions/checkout', { plan: this.selectedPlan.planId, currency: this.selectedPlan.currency })

        this.$chargebee.openCheckout({
          hostedPage: () => {
            return new Promise((resolve) => resolve(hostedPage))
          },
          loaded: function () {
          },
          close: async () => {
            await this.$store.dispatch('USER/updateSubscriptionStatus')
          },
          success: async (hostedPageId) => {
          },
          step: function (value) {

          }
        })
      } else {
        await this.$http.post('/subscriptions', { plan: this.selectedPlan.planId })
        await this.$store.dispatch('USER/updateSubscriptionStatus')
      }
    }
  }
}
</script>
