<template>
  <div
    class="p-2rounded-xl wp-block-column is-layout-flow"
    style="flex-basis:33.34%"
  >
    <p
      class="has-text-color"
      style="color:#46634f;font-size:26px;font-style:normal;font-weight:300"
    >
      <strong> {{ plan.name }} </strong>
    </p>
    <p
      style="font-size:30px;font-style:normal;font-weight:300"
    >
      <span v-if="currency != 'SEK'">{{ currencyIcons[currency] }}</span>{{ plan.price[commitment][currency] }} <span v-if="currency === 'SEK'">{{ currencyIcons[currency] }}</span>/mo
    </p>

    <div
      class="ub-buttons align-button-left"
      id="ub-button-21fd662d-76b5-42b1-a6eb-6fb7cacd1da4"
    >
      <div
        class="ub-button-container"
      >
        <a
          class="ub-button-block-main ub-button-small ub-button-flex-small"
          :class="{'opacity-75': current}"
          role="button"
          @click="(!current) ? $emit('select', plan) : null"
        >
          <div class="ub-button-content-holder">
            <span
              v-if="!current"
              class="ub-button-block-btn"
            >SELECT</span>
            <span
              v-else
              class="ub-button-block-btn"
            >CURRENT</span>
          </div>
        </a>
      </div>
    </div>
    <p
      v-for="entitlement in plan.entitlements[commitment]"
      :key="entitlement"
      class="leading-8 mb-4"

      style="font-size:16px;font-style:normal;font-weight:300"
    >
      {{ entitlement }}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    plan: {
      type: Object,
      required: true
    },
    commitment: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    current: Boolean
  },
  data () {
    return {
      currencyIcons: {
        SEK: 'kr',
        USD: '$',
        EUR: '€'
      }
    }
  }

}

</script>

<style scoped src="./css2.css"></style>
